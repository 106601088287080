import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './App.css';
import * as Papa from 'papaparse';
import Table from './Table';

//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './tables.css'



// Data
import win from './win.csv'


function App() {

  const [data, setData] = useState()
  const [runtimeData, setRuntimeData] = useState()
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = React.useState('');

  const handleSearch = (event) => {

    if(event.target.value === '' || !event.target.value) {
      setSearch(event.target.value)
      setRuntimeData(data)
      return;
    }

    setSearch(event.target.value);
    let searchData = data.filter((item) => {
      //console.log('item', item)
      return item.country_name.toLowerCase().includes(search.toLowerCase())
    });
    setRuntimeData(searchData)
  };


  useEffect(() => {
    fetch(win)
      .then(response => response.text())
      .then(responseText => {
        // -- parse csv
        var d = Papa.parse(responseText, {header: true});
        //('response', d)
        setLoading(false)
        setData(d.data)
        setRuntimeData(d.data)
      });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "ISO Alpha 3",
        accessor: "country_fips",
      },
      {
        Header: "Country Name",
        accessor: "country_name",
      },
      {
        Header: "Country URL",
        accessor: "country_url",
      },
      {
        Header: "Country Email",
        accessor: "country_email",
      },
      {
        Header: "Country Phone",
        accessor: "country_phone",
      },
    ]
  );
  
  if(!loading && runtimeData) {
    return (
        <div className="App">
          <Container>
            <Row>
              <Col xs={12}>
                <nav class="navbar navbar-expand-md align-items-end">
                  <a class="navbar-brand" href="#">
                    <img src="./win.png"  alt="win fertility logo" />
                  </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                    <li class="nav-item ml-auto">
                        <a class="nav-link" href="#!" style={{fontWeight: 'bold', color: '#000'}}>International Benefit Finder</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              <label htmlFor="search" className="mb-3">
                <div className="d-inline-block" style={{marginRight: '10px'}}>Search by Country Name:</div>
                <input id="search" className="ml-2 d-inline-block" type="text" onChange={handleSearch} />
              </label>
                <Table data={runtimeData} columns={columns} />
              </Col>
            </Row>
          </Container>
        </div>
    )
  } else {
    return ('Loading...')
  }
}

export default App;
